<template>
    <div class="animated fadeIn">
            <b-card>
                    <b-card-header>
                        Detail User <strong>( {{ this.name }} )</strong>
                    </b-card-header>
                    <b-card-body>
                        <div class="loading" v-if="isLoading == true">
                            <div class="sk-three-bounce">
                                <div class="sk-child sk-bounce1"></div>
                                <div class="sk-child sk-bounce2"></div>
                                <div class="sk-child sk-bounce3"></div>
                            </div>
                        </div>
                        <b-row class="mb-4">
                            <b-col sm="4">
                                <h6>Email</h6>
                                <div class="mb-3" v-if="this.email == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.email }}</strong></div>
                                <h6>Phone</h6>
                                <div class="mb-3" v-if="this.phone == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.phone }}</strong></div>
                                <h6>Address</h6>
                                <div class="mb-3" v-if="this.address == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.address }}</strong></div>
                                <h6>ID Card Number</h6>
                                <div class="mb-3" v-if="this.cardPhoto == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.cardNumber }}</strong></div>
                                <h6>Taxpayer ID Number</h6>
                                <div class="mb-3" v-if="this.taxpayerNumber == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.taxpayerNumber }}</strong></div>
                                <h6>Birthday</h6>
                                <div class="mb-3" v-if="this.birthday == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.birthday }}</strong></div>
                            </b-col>
                            <b-col sm="4">
                                <h6>Gender</h6>
                                <div class="mb-3" v-if="this.gender == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.gender }}</strong></div>
                                <h6>Country</h6>
                                <div class="mb-3" v-if="this.country == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.country }}</strong></div>
                                <h6>Postal Code</h6>
                                <div class="mb-3" v-if="this.postalCode == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.postalCode }}</strong></div>
                                <h6>Teman Treasury Status</h6>
                                <div class="mb-3" v-if="this.status != null">
                                    <strong>
                                        {{ this.referral_code == null && this.referral_status == false ? 'Deactive' : 'Active' }}
                                    </strong>
                                </div>
                                <div class="mb-3" v-else><strong>Referral Code Tidak Ada</strong></div>
                                <h6>eWallet Balance</h6>
                                <div class="mb-3" v-if="this.ewallet == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.ewallet | currency }}</strong></div>
                                <h6>Gold Balance</h6>
                                <div class="mb-3" v-if="this.gold == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.gold + " Gram"}}</strong></div>
                            </b-col>
                            <b-col sm="4">
                                <h6>Photo</h6>
                                <div class="mb-3" v-if="this.photo != null"><b-img :src="this.photo" fluid alt="Foto"  style="max-width: 300px; max-height: 300px;"></b-img></div>
                                <div class="mb-3" v-else><strong> - </strong></div>
                                <h6>ID Card Photo</h6>
                                <div class="mb-3" v-if="this.cardPhoto != null"><a :href="this.cardPhoto" target="_blank">Open File <span class="fa fa-external-link"></span></a></div>
                                <div class="mb-3" v-else><strong>-</strong></div>
                                <h6>Register Date</h6>
                                <div class="mb-3" v-if="this.registerDate == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.registerDate }}</strong></div>
                                <h6>Last Update Data</h6>
                                <div class="mb-3" v-if="this.lastUpdate == null"><strong>-</strong></div>
                                <div class="mb-3" v-else><strong>{{ this.lastUpdate }}</strong></div>
                                <h5><strong>Heir Information</strong></h5>
                                <div class="mb-3" v-if="this.heirInfo == null"><strong> - </strong></div>
                                <div class="mb-3" v-else>
                                    <h6>Name</h6>
                                    <div class="mb-3" v-if="this.heirInfo.name == null"> - </div>
                                    <div class="mb-3" v-else><strong>- {{ this.heirInfo.name }}</strong></div>
                                    <h6>Date of Birth</h6>
                                    <div class="mb-3" v-if="this.heirInfo.date_of_birth == null"> - </div>
                                    <div class="mb-3" v-else><strong>- {{ this.heirInfo.date_of_birth }}</strong></div>
                                    <h6>Relationship</h6>
                                    <div class="mb-3" v-if="this.heirInfo.relationship == null"> - </div>
                                    <div class="mb-3" v-else><strong>- {{ this.heirInfo.relationship }}</strong></div>
                                    <h6>ID Card Number</h6>
                                    <div class="mb-3" v-if="this.heirInfo.id_card_number == null"> - </div>
                                    <div class="mb-3" v-else><strong>- {{ this.heirInfo.id_card_number }}</strong></div>
                                    <h6>ID Card Photo</h6>
                                    <div class="mb-3" v-if="this.heirInfo.id_card_photo_url == null"> - </div>
                                    <div class="mb-3" v-else><a :href="this.heirInfo.id_card_photo_url" target="_blank">Open File <span class="fa fa-external-link"></span></a></div>
                                    <h6>Family Card Photo</h6>
                                    <div class="mb-3" v-if="this.heirInfo.family_card_photo_url == null"> - </div>
                                    <div class="mb-3" v-else><a :href="this.heirInfo.family_card_photo_url" target="_blank">Open File <span class="fa fa-external-link"></span></a></div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-header>
                        Detail Teman Treasury
                    </b-card-header>
                    <b-card-body>
                        <b-row class="mb-4">
                            <b-col sm="4">
                                <h6>Point Teman Treasury</h6>
                                <div class="mb-3" v-if="this.myPoint == null"> - </div>
                                <div class="mb-3" v-else><strong>{{ this.myPoint == 0  ? this.myPoint+" Point" : this.myPoint+" Points" }}</strong></div>
                                <h6>Status</h6>
                                <div class="mb-3" v-if="this.status != null">
                                    <strong>
                                        {{ this.status == null && this.referral_status == false ? 'Deactive' : 'Active' }}
                                    </strong>
                                </div>
                                <div class="mb-3" v-else><strong> Refferral Code Tidak Ada</strong></div>
                            </b-col>
                            <b-col sm="4">
                                <h6>Direkrut oleh</h6>
                                <div class="mb-3" v-if="this.recruitFrom == null"> - </div>
                                <div class="mb-3" v-else> <strong v-html="this.recruitFrom"></strong></div>
                                <h6>Sahabat</h6>
                                <div class="mb-3" v-if="this.sahabat == null"> - </div>
                                <div class="mb-3" v-else><strong v-html="this.sahabat"></strong></div>
                                <h6>Kawan</h6>
                                <div class="mb-3" v-if="this.kawan == ''"> - </div>
                                <div class="mb-3" v-else> <strong v-html="this.kawan"></strong></div>
                            </b-col>
                            <b-col sm="4">
                                <h6>Bonus Gold</h6>
                                <div class="mb-3" v-if="this.goldBonus == null"> - </div>
                                <div class="mb-3" v-else><strong>{{ this.goldBonus == 0 ? this.goldBonus+" Gram" : this.goldBonus+" Grams"}}  </strong></div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-header v-if="!this.verified && $can('approve') && $can('reject')">
                        Approve Or Reject Teman Treasury
                    </b-card-header >
                    <b-card-body v-if="!this.verified && $can('approve') && $can('reject')">
                        <b-row>
                            <b-col sm="5">
                                <h6>Approve Teman Treasury</h6>
                                <b-button variant="success" @click="approved">Approve</b-button>
                            </b-col>
                            <b-col sm="5">
                                <h6>Reject Teman Treasury</h6>
                                <form v-on:submit.prevent="reject">
                                    <b-form-group id="reasonInputGroup1"
                                                    label-for="reason">
                                        <b-form-input id="reason"
                                                    type="text"
                                                    size="md"
                                                    v-model.lazy.trim="$v.form.reason.$model"
                                                    :state="chkState('reason')"
                                                    aria-describedby="reasonFeedback"
                                                    placeholder="Please Enter Reason Title"
                                                    autocomplete='given-name'
                                                    autofocus >
                                        </b-form-input>
                                        <b-form-invalid-feedback id="reasonFeedback" v-for="(error , index) in errors.form.reason" :key="index">
                                        - {{ error }} <br>
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </form>
                            </b-col>
                            <b-col sm="2">
                                <b-input-group-append style="margin-top:20px">
                                    <b-button size="md" type="submit" @click="reject" variant="success">Button</b-button>
                                </b-input-group-append>
                            </b-col>
                        </b-row>
                    </b-card-body>
            </b-card>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
export default {
    name: 'detail-user',
    data() {
        return {
            form: {
                reason: "",
            },
            statusChecked: false,
            email: "",
            phone: "",
            address: "",
            cardNumber: "",
            taxpayerNumber: "",
            birthday: "",
            gender: "",
            country: "",
            postalCode: "",
            status: "",
            referral_code: "",
            referral_status: "",
            ewallet: "",
            gold: "",
            photo: "",
            cardPhoto: "",
            registerDate: "",
            lastUpdate: "",
            heirInfo: "",
            accessRight: "",
            accressRights: [],
            reason: "",
            name: "",
            user: '',
            goldBonus: '',
            recruitFrom: '',
            sahabat: '',
            kawan: '',
            verified: '',
            myPoint: '',
            feature: '',
            errors: {
                message: [],
                status: [],
                code: '',
                headers: [],
                form: {
                    reason: [],
                },
            },
            isLoading: false
        }
    },
    computed: {
        formStr() { return JSON.stringify(this.form, null, 4) },
        isValid() { return !this.$v.form.$anyError },
        isDirty() { return this.$v.form.$anyDirty },
        invCheck() { return 'You must accept before submitting' },
    },
    mixins: [validationMixin],
    validations: {
        form: {
            reason: {
                required
            }
        }
    },
    created () {

        this.$http.get(`detail-tt/` + this.$route.params.id)
        .then((result) => {
            // this.user = result.data.user.id;\
            this.user = result.data.meta.data.user.id;
            this.name = result.data.meta.data.user.name;
            this.email = result.data.meta.data.user.email;
            this.phone = result.data.meta.data.user.phone;
            this.address = result.data.meta.data.user.address;
            this.cardNumber = result.data.meta.data.user.id_number;
            this.taxpayerNumber = result.data.meta.data.user.tax_id_number;
            this.birthday = result.data.meta.data.user.birthday;
            this.gender = result.data.meta.data.user.gender;
            this.country = result.data.meta.data.user.country;
            this.postalCode = result.data.meta.data.user.postal_code;
            this.status = result.data.meta.data.temanTreasury;
            this.referral_code = result.data.meta.data.temanTreasury.referral_code;
            this.referral_status = result.data.meta.data.temanTreasury.status;
            this.ewallet = result.data.meta.data.user.wallet_balance;
            this.gold = result.data.meta.data.user.gold_balance;
            this.photo = result.data.meta.data.user.photo;
            this.cardPhoto = result.data.meta.data.user.id_card_scan;
            this.registerDate = result.data.meta.data.user.created_at;
            this.lastUpdate = result.data.meta.data.user.updated_at;
            this.heirInfo = result.data.meta.data.heir;
            this.myPoint = result.data.meta.data.temanTreasury.my_points;
            this.goldBonus = result.data.meta.data.temanTreasury.gold_bonus;
            this.referral_status = result.data.meta.data.temanTreasury.status;
            this.sahabat = result.data.meta.data.temanTreasury.recruiting_to.sahabat;
            this.kawan = result.data.meta.data.temanTreasury.recruiting_to.kawan;
            this.recruitFrom = result.data.meta.data.temanTreasury.recruit_from;
            this.verified = result.data.meta.data.temanTreasury.is_verified;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.data.meta.message;
            this.errors.status = error.response.data.meta.code;
          }
        })
    },
    methods: {
        approved() {
            this.isLoading = true
            this.$http.post(`approve-tt`, {
                user_id: this.user
            }).then(() => {
                this.isLoading = false
                this.$swal.fire(
                    'Success!',
                    'Teman treasury was successfully approved.',
                    'success'
                ).then(() =>{
                    location.reload()
                })
            }).catch((error) => {
                if (error.response) {
                    this.isLoading = false
                    this.errors.code = error.response.status;
                    this.errors.status = error.response.data.meta.code;
                    this.errors.headers = error.response.headers;
                }
            })
        },
        reject() {
            this.validate()
            this.isLoading = true
            this.$http.post(`reject-tt`, {
                user_id: this.user,
                reason: this.form.reason
            }).then(() => {
                this.isLoading = false
                this.$swal.fire(
                    'Success!',
                    'Teman treasury was successfully rejected.',
                    'success'
                ).then(() =>{
                    location.reload()
                })
            }).catch((error) => {
                if (error.response) {
                    this.isLoading = false
                    this.errors.code = error.response.status;
                    this.errors.status = error.response.data.meta.code;
                    this.errors.headers = error.response.headers;
                    if(this.errors.status == 422) {
                        this.errors.message = error.response.data.meta.message;
                        this.errors.form.reason = this.errors.message.reason;
                    }
                }
            })
        },
        chkState(val) {
            const field = this.$v.form[val]
            return !field.$dirty || !field.$invalid
        },
        findFirstError(component = this) {
            if (component.state === false) {
                if (component.$refs.input) {
                component.$refs.input.focus()
                return true
                }
                if (component.$refs.check) {
                component.$refs.check.focus()
                return true
                }
            }
            let focused = false
            component.$children.some((child) => {
                focused = this.findFirstError(child)
                return focused
            })

            return focused
            },
        validate() {
            this.$v.$touch()
            this.$nextTick(() => this.findFirstError())
            return this.isValid
        }
    },
}
</script>

<style>
    .submt {
        width: 100%;
        height: auto;
        display: block;
    }
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
